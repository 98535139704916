import React, { FunctionComponent, useEffect } from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  List,
  Toolbar,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { styled } from '@mui/material/styles'
import { handleNavigationClick, isModuleVisible } from '../../../helpers/utils'
import TcreateLogo from '../../../../src/assets/images/logos/tcreate-logo.png'
import { ReactComponent as ContactIcon } from '../../../assets/images/icons/contact_icon.svg'
import { history } from '../../../helpers/history'
import { useTranslation } from 'react-i18next'
import { ApplicationModule } from '../../../store/Config/types'
import ConfigService from '../../../services/config.service'
import { errorHandler } from '../../../helpers/errorHandler'
import HomeIcon from '@mui/icons-material/Home'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'

const drawerWidth = 240

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#373739',
    color: '#DCDCDC',
    fill: '#DCDCDC',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '.active-path': {
      color: theme.colorsPalette.yellow.main,
      svg: {
        fill: theme.colorsPalette.yellow.main,
      },
    },
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
  '.tcreate-logo': {
    position: 'absolute',
    right: '93px',
  },
  '.hide-icon': {
    fill: theme.colorsPalette.yellow.main,
  },
  '.side-navbar-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    overflowX: 'hidden',
    '.side-navbar-menu-list': {
      scrollbarWidth: 'thin',
      scrollbarColor: 'rgba(255,255,255, .4) rgba(255,255,255, .2)',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(255,255,255, .2)',
        borderRadius: '5vw',
        transition: '3s',

        '&:hover': {
          backgroundColor: 'rgba(255,255,255, .4)',
        },
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(255,255,255, 0)',
        borderRadius: '5vw',
        transition: '3s',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, .2)',
        },
      },
    },
  },
  '.nav-footer-container': {
    overflow: 'hidden',
    color: theme.colorsPalette.gray.gray4,
    fontSize: '.875rem',
    '.nav-footer-txt': {
      '&_version': {
        margin: '10px 0px',
      },
      '&_www': {
        a: {
          marginLeft: '5px',
        },
      },
      a: {
        color: theme.colorsPalette.gray.gray4,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  '.contact-icon': {
    width: '24px',
    fill: theme.colorsPalette.orange.medium,
  },
  '.prevent-default-btn': {},
}))

type NavigationProps = {
  open: boolean
  handleDrawerClose: () => void
  currentUser: any
  applicationModules: ApplicationModule[]
}

const Navigation: FunctionComponent<NavigationProps> = ({
  open,
  handleDrawerClose,
  currentUser,
  applicationModules,
}) => {
  const VERSION = process.env.REACT_APP_VERSION
  const { t } = useTranslation()
  const [currentLocation, setCurrentLocation] = React.useState<string>(
    history.location.pathname,
  )
  const [apiVersion, setApiVersion] = React.useState('')
  useEffect(() => {
    history.listen(() => {
      setCurrentLocation(history.location.pathname)
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ApiVersionResponse = await ConfigService.getApiVersion()

        if (ApiVersionResponse.data.apiVersion) {
          setApiVersion(ApiVersionResponse.data.apiVersion)
        }
      } catch (error) {
        errorHandler(error, t)
      }
    }
    fetchData()
  }, [t])

  const renderMenuItem = (
    routeName: string,
    translation: string,
    icon: React.ReactNode,
    isRoot = false,
    isSubItem = false,
    possibleRoutes?: string[],
  ) => {
    const routes = possibleRoutes ? possibleRoutes : []
    const routeRegex = new RegExp(`^/${routeName}(|/|/(.*))$`, 'g')

    return (
      <ListItem
        className={
          routeRegex.test(currentLocation) ||
          currentLocation === (isRoot ? '/' : '') ||
          routes.includes(currentLocation)
            ? 'active-path'
            : ''
        }
        button
        onClick={() => handleNavigationClick(routeName)}
        sx={{ pl: isSubItem ? 4 : 2 }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={translation} />
      </ListItem>
    )
  }

  const renderListItems = () => {
    return (
      <div>
        {renderMenuItem(
          'home',
          t('navigation.home'),
          <HomeIcon color="secondary" />,
          true,
        )}
        {isModuleVisible('TOOLTIP', applicationModules, currentUser) &&
          renderMenuItem(
            'tooltips',
            t('navigation.tooltips'),
            <TipsAndUpdatesIcon color="secondary" />,
            false,
          )}
      </div>
    )
  }

  return (
    <Drawer variant="permanent" open={open} sx={{ height: '100vh' }}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <img className="tcreate-logo" src={TcreateLogo} alt="" />
          <ChevronLeftIcon className="hide-icon" />
        </IconButton>
      </Toolbar>
      <Divider />
      <div className="side-navbar-container">
        <List
          className="side-navbar-menu-list"
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {renderListItems()}
        </List>
      </div>
      <ListItem>
        <ListItemIcon>
          <ContactIcon className="contact-icon" />
        </ListItemIcon>
        <div className="nav-footer-container">
          <div className="nav-footer-txt">
            <div className="nav-footer-txt_www">
              Copyright
              <a
                href="https://tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                tcreate.pl
              </a>
            </div>
            <div className="nav-footer-txt_phone">+48 512 802 802</div>
            <div className="nav-footer-txt_mail">
              <a
                href="mailto:support@tcreate.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@tcreate.pl
              </a>
            </div>
            <small
              style={{
                opacity: 0.4,
              }}
            >
              api: {apiVersion} | web: {VERSION}
            </small>
          </div>
        </div>
      </ListItem>
    </Drawer>
  )
}

export default Navigation
