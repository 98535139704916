import React, { Suspense, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import './App.css'
import './locales/i18n'

import Login from './components/Pages/Login/Login'

import { clearMessage } from './actions/message'
import { history } from './helpers/history'
import Header from './components/Pages/shared/Header'
import Navigation from './components/Pages/shared/Navigation'
import { checkAuth } from './helpers/checkAuth'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/system'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { ApplicationModule } from './store/Config/types'
import ConfigService from './services/config.service'
import { errorHandler } from './helpers/errorHandler'
import { useTranslation } from 'react-i18next'
import { isModuleVisible } from './helpers/utils'
import LoadingSpinner from './components/shared/LoadingSpinner'

const PasswordReset = React.lazy(
  () => import('./components/Pages/PasswordReset/PasswordReset'),
)

const Home = React.lazy(() => import('./components/Pages/Home/Home'))
const Tooltips = React.lazy(
  () => import('./components/Pages/Tooltips/Tooltips'),
)

const RootDiv = styled('div')({
  display: 'flex',
})

const MainContent = styled('main')({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
})

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState<boolean>(false)
  const [applicationModules, setApplicationModules] = React.useState<
    ApplicationModule[]
  >([])
  const { user: currentUser } = useSelector(
    (state: RootStateOrAny) => state.auth,
  )
  const { isLoggedIn } = useSelector((state: RootStateOrAny) => state.auth)
  const [loading, setLoading] = React.useState<boolean>(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage())
    })
  }, [dispatch])

  useEffect(() => {
    if (currentUser) {
      checkAuth(currentUser.token)
    }
  }, [currentUser])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const applicationModulesResponse =
          await ConfigService.getApplicationModules()
        if (applicationModulesResponse.data) {
          setApplicationModules(applicationModulesResponse.data.modules)
        }
      } catch (error) {
        errorHandler(error, t)
      } finally {
        setLoading(false)
      }
    }
    if (isLoggedIn) {
      fetchData()
    }
    if (!isLoggedIn) {
      setLoading(false)
    }
  }, [t, isLoggedIn])

  return (
    <RootDiv>
      <CssBaseline />
      {currentUser && isLoggedIn && !loading && (
        <>
          <Header
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
          <Navigation
            open={open}
            handleDrawerClose={handleDrawerClose}
            currentUser={currentUser}
            applicationModules={applicationModules}
          />
        </>
      )}
      <MainContent>
        {loading && <LoadingSpinner />}

        {!loading && (
          <Router history={history}>
            <div style={{ height: '100px' }} />
            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                {isModuleVisible(
                  'TOOLTIP',
                  applicationModules,
                  currentUser,
                ) && <Route path={['/tooltips']} component={Tooltips} />}

                <Route exact path={['/', '/home']} component={Home} />

                <Route exact path="/login" component={Login} />
                <Route
                  path="/password/reset/:token"
                  component={PasswordReset}
                />
                {/* If root is incorrect, page redirects user to the Home page */}
                <Route path="*">
                  <Redirect to="/home" />
                </Route>
                <Redirect to="/login" />
              </Switch>
            </Suspense>
          </Router>
        )}

        {currentUser && (
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ whiteSpace: 'pre-line' }}
          />
        )}
      </MainContent>
    </RootDiv>
  )
}

export default App
